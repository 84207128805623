<template>
  <app-modal-list-payment-detail
    :show="showModalDetail"
    @close="showModalDetail = false"
    :payment_link="selectTransactionList"
    :showBusinessDetails="isSupportRole"
    @update:payment_link="selectTransactionList = $event"
  />

  <app-modal 
  :show="showModalDownloadCSV"
  @close="showModalDownloadCSV= false"
  :textButtonCancel="$t('payment.info.okay')">
    <template v-slot:body>
      <p class="text-sm text-gray-500">
        {{ $t("payout.modal.csv_download_description") }}
      </p>
    </template>
  </app-modal>

  <app-layout class="bg-white">
    <div class="container mx-auto">
      <div class="mx-4 my-5 flex flex-row justify-between items-center">
        <span class="text-xl">{{
          $t("account.transaction.transaction_list")
        }}</span>
      </div>
      <hr class="mx-4 w-full border-b border-gray-200 mb-5" />
      <app-table
        :showEmptyButton="false"
        :apiResponse="apiPaginationResponse.data"
        :loading="apiPaginationLoading"
        @params-changed="params_changed"
        @on-item-click="onItemClick"
        :filters="[
          {
            key: 'filter[payment_link_id]',
            type: 'text',
            placeholder: $t('account.transaction.payment_id'),
            value: '',
          },
          {
            key: 'filter[reference_id]',
            type: 'text',
            placeholder: $t('account.transaction.reference_id'),
          },
          {
            key: 'filter[amount]',
            type: 'text',
            placeholder: $t('account.transaction.sale_amount'),
          },
          {
            key: 'filter[has_status]',
            type: 'select',
            placeholder: $t('account.transaction.paid_payment_status'),
            value: {},
            items: [
              {
                id: 'allPaid',
                name: 'Paid',
              },
              {
                id: 'unpaid',
                name: 'Unpaid',
              },
              {
                id: 'all',
                name: 'All',
              },
            ],
          },
          {
            key: 'filter[title]',
            type: 'text',
            placeholder: $t('account.transaction.payment_purpose'),
          },
          {
            key: 'filter[business_name]',
            type: 'text',
            placeholder: $t('account.transaction.business_name'),
          },
          {
            key: 'filter[reference]',
            type: 'text',
            placeholder: $t('account.transaction.reference_1'),
          },
          {
            key: 'filter[reference_2]',
            type: 'text',
            placeholder: $t('account.transaction.reference_2'),
          },
          {
            key: 'filter[created_at_between]',
            type: 'date',
            placeholder: $t('account.transaction.date_created'),
            value: '',
          },
          {
            key: 'filter[payment_time_between]',
            type: 'date',
            placeholder: $t('account.transaction.date_payment'),
            value: '',
          },
          {
            key: 'filter[payment_method]',
            type: 'select',
            placeholder: $t('account.transaction.payment_type'),
            value: {},
            items: [
              {
                id: 'Online Banking B2C',
                name: 'Online Banking B2C',
              },
              {
                id: 'Online Banking B2B',
                name: 'Online Banking B2B',
              },
              {
                id: 'Credit / Debit Card',
                name: 'Credit/Debit Card',
              },
              {
                id: 'Wallet',
                name: 'eWallet',
              },
            ],
          },
        ]"
      >
        <template v-slot:button>
          <app-button
            @click="downloadCsvTransactionList"
            :showf70Icon="false"
            :disabled="loading"
            type="button"
            class="sm:ml-3 sm:w-auto"
          >
            {{ $t("payout.modal.download_csv_button") }}
          </app-button>
        </template>
        <template v-slot:header>
          <th class="font-bold">{{ $t("account.transaction.payment_id") }}</th>
          <th class="font-bold">
            {{ $t("account.transaction.reference_id") }}
          </th>
          <th class="font-bold">{{ $t("account.transaction.sale_amount") }}</th>
          <th class="font-bold">
            {{ $t("account.transaction.payment_status") }}
          </th>
          <th class="font-bold">
            {{ $t("account.transaction.business_name") }}
          </th>
          <th class="font-bold">
            {{ $t("account.transaction.date_created") }}
          </th>
          <th class="font-bold">
            {{ $t("account.transaction.date_payment") }}
          </th>
        </template>
        <template v-slot:body="data">
          <td>
            {{ data.model._id }}
          </td>
          <td>
            {{
              getPayoutInfo(
                getWalletInfo(
                  getWallet(data.model.settlement ?? []).wallet ?? []
                ).payout ?? []
              ).reference_id ?? "-"
            }}
          </td>
          <td>
            <p class="font-medium">
              {{
                $formats.currency(data.model.currency, data.model.amount ?? 0.0)
              }}
            </p>
          </td>
          <td class="flex place-items-center space-x-2 justify-left">
            <app-badge
              :status="data.model.paid ? 'success' : 'light'"
              class="w-16 text-xs"
            >
              {{
                data.model.paid
                  ? $t("payment.detail.paid")
                  : $t("payment.detail.unpaid")
              }}
            </app-badge>
          </td>
          <td>
            {{ data.model.business.name }}
          </td>
          <td>
            {{ $moment(data.model.created_at).format("DD MMM YYYY hh:mm a") }}
          </td>
          <td>
            {{
              data.model.paid
                ? $moment(
                    Number(
                      getPaymentDate(data.model?.payments)?.payment_time?.$date
                        ?.$numberLong
                    )
                  ).format("DD MMM YYYY hh:mm a")
                : "-"
            }}
          </td>
        </template>
        <!--footer for Total sale amount-->
        <template v-slot:footer>
          <tr>
            <td class="font-bold" colspan="1">
              {{ $t("wallet.transaction.total") }}
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td class="font-bold">
              {{
                $formats.currency(
                  apiPaginationResponse?.data?.summary?.currency,
                  apiPaginationResponse?.data?.summary?.total_amount ?? 0.0
                )
              }}
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
        </template>
      </app-table>
    </div>
  </app-layout>
</template>
<script>
import ROLE from "@/utils/const/role";
export default {
  data() {
    return {
      ROLE: ROLE,
      showModalDetail: false,
      showModalDownloadCSV: false,
      selectTransactionList: null,
      queryParams: "",
      filters: "",
    };
  },
  mounted() {
    this.fetchTransactionList();
  },
  computed: {
    apiPaginationResponse() {
      return this.$store.getters["paymentStore/apiPaginationResponse"];
    },
    apiPaginationLoading() {
      return this.$store.getters["paymentStore/apiPaginationLoading"];
    },
    apiPaginationPaymentLink() {
      return this.$store.getters["paymentStore/apiPaginationResponse"];
    },
    selectedRole() {
      return this.$store.getters["authStore/selectedRole"];
    },
  },
  methods: {
    params_changed(filters, queryParams) {
      this.queryParams = queryParams;
      this.fetchTransactionList();
    },

    onItemClick(data) {
      this.showModalDetail = !this.showModalDetail;
      this.selectTransactionList = data;
    },

    per_page_changed(per_page) {
      this.filters.per_page = per_page;
      this.fetchTransactionList();
    },

    async fetchTransactionList() {
      await this.$store.dispatch(
        "paymentStore/getTransactionList",
        this.queryParams
      );
    },

    async downloadCsvTransactionList() {
      this.showModalDownloadCSV = true;
      this.submitLoading = true;
      const csv_url = await this.$store.dispatch(
        "paymentStore/downloadTransactionList",
        this.queryParams
      );
      this.submitLoading = false;
      this.fetchTransactionList();
    },

    getWallet(settlement) {
      var Settlementdata = {
        business_id: settlement._id,
        _id: settlement.business_id,
        wallet: settlement.wallet,
      };
      return Settlementdata;
    },

    getWalletInfo(wallet) {
      var Walletdata = {
        _id: wallet._id,
        reference_id: wallet.reference_id,
        payout: wallet.payout,
      };
      return Walletdata;
    },

    getPayoutInfo(payout) {
      var PayoutData = {
        reference_id: payout.reference_id,
      };
      return PayoutData;
    },

    getPaymentDate(payments) {
      if (payments == null) return "-";

      var paymentAmount = {
        payment_time: payments.payment_time,
      };

      payments.forEach((payment) => {
        paymentAmount.payment_time = payment.payment_time;
      });

      return paymentAmount;
    },
  },
};
</script>
